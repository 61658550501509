const CLIENT_SITE_NAME = 'ExaRoutes'

type ENVType = 'local' | 'staging' | 'production'

let environment = process.env.REACT_APP_ENV as ENVType | undefined

let clientSiteURL
let serverURL

let googleClientId

let recaptchaSiteKey = ''

let paypalClientId
let paypalPlanId

console.log('environmentenvironmentenvironment', environment)
if (environment === 'production') {
  // Add production variables
  recaptchaSiteKey = '6LedCfMpAAAAAEC_WQ27LqfN7rJZ5dfnaipGDaka'
} else if (environment === 'staging') {
  clientSiteURL = 'https://staging.exaroutes.com'
  serverURL = 'https://api.staging.exaroutes.com'

  googleClientId =
    '882486261796-4ufhbv45hhvqhm3c2klrkm5vl1kunpo9.apps.googleusercontent.com'
  recaptchaSiteKey = '6LedCfMpAAAAAEC_WQ27LqfN7rJZ5dfnaipGDaka'

  paypalClientId = process.env.REACT_APP_PAYPAL_CLIENT_ID
  paypalPlanId = process.env.REACT_APP_PAYPAL_PLAN_ID
  console.log(
    'Inside staging config',
    process.env,
    process.env.PAYPAL_CLIENT_ID,
    process.env.PAYPAL_PLAN_ID
  )
} else {
  environment = 'local'
  clientSiteURL = 'http://localhost:3000'
  serverURL = 'http://localhost:4000'

  googleClientId =
    '882486261796-4ufhbv45hhvqhm3c2klrkm5vl1kunpo9.apps.googleusercontent.com'

  // paypalClientId =
  //   'AYoCCFPQT5BwdV1zxbPKqwJTQcJfaF3bGocXGqbruPhTSOOrgtIwqMq4aoSA9lrWHD9JvyJ-vDEnCmgR'
  // paypalPlanId = 'P-9BV49797U9663115KM4RQ5XY'
  paypalClientId = 'ARRFDY1EQuJdryS6CV3UO29De0e1w3DrEf63H1K1Zeyd0eJ_Neo5zlya63KKdQVW33UqRKtcUwSquD-v'
  // paypalPlanId = 'P-66L008828U3613109M4NCVXQ'
  // paypalPlanId = 'P-5G11619000516615RM4MVCVQ'
  paypalPlanId = 'P-5AT80759LW615280SM6H7HPI'
}

const ENV = environment

const CLIENT_SITE_URL = clientSiteURL as string
const SERVER_URL = serverURL as string

const GOOGLE_CLIENT_ID = googleClientId as string

const PAGINATION_SIZE = 20
const SUPPORT_EMAIL = 'support@exaroutes.com'

const RECAPTCHA_SITE_KEY = recaptchaSiteKey

const PAYPAL_CLIENT_ID = paypalClientId as string
const PAYPAL_PLAN_ID = paypalPlanId as string

export {
  ENV,
  CLIENT_SITE_NAME,
  CLIENT_SITE_URL,
  SERVER_URL,
  PAGINATION_SIZE,
  GOOGLE_CLIENT_ID,
  SUPPORT_EMAIL,
  RECAPTCHA_SITE_KEY,
  PAYPAL_CLIENT_ID,
  PAYPAL_PLAN_ID
}
